import React from 'react'
import App from 'App'
import { SITE_LINK_PUBLISHING } from 'consts'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import imageHeader from 'static/images/integrations/twitter/header_picture.webp'
import imageToolManage from 'static/images/integrations/twitter/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/twitter/image_text_1.webp'
import imageText2 from 'static/images/integrations/twitter/image_text_2.webp'
import imageText3 from 'static/images/integrations/twitter/image_text_3.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import { Flex } from 'components/Layout'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const NETWORK = 'Twitter'

const TEXT_IMAGES = [
  {
    header: {
      title: `Simplify ${NETWORK} Content Development & Profile Posting`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily Plan, Publish and Boost Posts',
        description: `Draft posts for one or many ${NETWORK} Profiles — upload videos, attach images from a Media Library, apply targeting and add tags.`,
      },
      {
        title: 'Manage the Content Calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar — quickly filter by tag, profile and date.`,
      },
      {
        title: 'Implement Approval Workflows',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: 'Efficiently Manage Followers & Messages as a Team',
      matches: [],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: 'Manage the Social Inbox as a Team',
        description:
          'Prevent duplication of efforts with team tools — mark as complete, view audit trails and track real-time progress.',
      },
      {
        title: 'Stay Productive With Intuitive Workflows',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar — quickly filter by tag, profile and date.`,
      },
      {
        title: 'Easily Customize Access & Governance',
        description:
          'Organize teams and profiles to fit your needs — great for agencies, multi-location or global businesses.',
      },
    ],
  },
  {
    header: {
      title: `Schedule ${NETWORK} Threads & Supercharge Your Engagement`,
      matches: [`${NETWORK} Threads`],
    },
    textFirst: false,
    image: imageText3,
    items: [
      {
        title: `Publishing threads alongside your tweets`,
        description: 'Easily start your threads when publishing your tweets.',
      },
      {
        title: 'Add images and videos to your thread',
        description: `Import media or grab content straight from Canva, Giphy, etc.`,
      },
      {
        title: 'Add links and mentions to your thread',
        description: 'Include links and mentions in your threaded discussions.',
      },
    ],
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} profiles and Messenger conversations.`}
        path="/integrations/twitter/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} profiles. Seamlessly execute social media management and social customer care on Twitter from a single, scalable platform.`}
        image={imageHeader}
      />

      <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
        <G2FeaturesComponent />
      </Flex>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} Profiles`}
        description={`Vista Social’s ${NETWORK} integration includes publishing, boosting, reputation and response management tools as well as rich analytics. Plan content, engage your audience, collaborate as a team and measure efforts from a single platform.`}
        image={imageToolManage}
        tools={[
          {
            title: `Easily Share Content With Your Audience`,
            description:
              "Plan, categorize and intelligently distribute Tweets using Vista's advanced Twitter publishing and planning features.",
          },
          {
            title: 'Manage Conversations & Build Relationships',
            description:
              'Personally engage with and grow your Twitter followers using social tools within a unified inbox of messages.',
          },
          {
            title: `Benchmark Your ${NETWORK} Performance`,
            description: `Measure follower growth, engagement, impressions, Twitter keyword usage and more with presentation-ready reports.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES} />

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
